import * as React from 'react';
import { NavLink as Link } from 'react-router-dom';
import { SliderPageScrollEvents } from 'src/components/widgets/SliderPage/SliderPage';
import {SliderPageContextType} from '../../contexts/SliderPageContext';
// import CustomMap from '../CustomMap/CustomMap';
import './Footer.scss';

interface IFooterState {
    activeFields: any;
}

export default class Footer extends React.Component<any, IFooterState> {
    declare context: SliderPageContextType;

    public map: HTMLDivElement | null;

    public form: HTMLFormElement | null;

    constructor(props: any) {
        super(props);
        this.state = {
            activeFields: new Map()
        }

        this.state.activeFields.set("name", "");
        this.state.activeFields.set("phone", "");

        this.focusInput = this.focusInput.bind(this);

        this.formSubmit = this.formSubmit.bind(this);
    }

    public componentDidMount() {
        this.context.addKeydown();    
    }

    public focusInput(e: React.SyntheticEvent<HTMLInputElement>) {
        const value = e.currentTarget.id;
        const fields = this.state.activeFields;

        fields.set(value, "active");

        this.setState({activeFields: fields});

        this.context.removeKeydown();
    }

    public formSubmit(event: React.SyntheticEvent<HTMLButtonElement>) {
        event.preventDefault();
        const name = (this.form!.childNodes[0].childNodes[1] as HTMLInputElement).value;
        const phone = (this.form!.childNodes[1].childNodes[1] as HTMLInputElement).value;
        const regName = /^[а-яА-Яa-zA-Z]{2,20}$/;
        const regPhone = /^(\d|\s){7,25}$/;
        const fields = this.state.activeFields;

        this.context.addKeydown();

        if (name.match(regName) && phone.match(regPhone)) {
            fetch(`http://api.транспорт.онлайн/send?name=${name}&phone=${phone}`, {
                method: 'POST',
                mode: 'cors',
                headers: {'Content-Type': 'charset=utf-8'},
            })
            .then( res => {
                fields.set('name', "");
                fields.set('phone', "");

                this.setState({activeFields: fields});
                this.form!.reset();
                alert('Данные успешно отправленны');
                
            })
            .catch( err => console.log(err));
        } else {
            alert('Введите имя и телефон в нужном формате.\n' +
            'Пример:\n' +
            'Имя: Иван\n' +
            'Телефон: 8 900 000 00 00');
        };
    }

    public render() {
        const classFormLable = this.state.activeFields;
        return (
            <footer className="footer">
                <h2 className="footer__title">Заказать обратный звонок </h2>
                <p className="footer__subtext">Наши специалисты свяжутся с Вами в течение 1 часа</p>
                <form className="footer__form" ref={elem => this.form = elem}>
                    <label className={`footer__form-item name ${classFormLable.get('name')}`}>
                        <span className={`footer__form-lable`}>Имя</span>
                        <input type="text" 
                            name="name" 
                            id="name" 
                            onFocus={this.focusInput} required={true}/>
                    </label>
                    <label className={`footer__form-item phone ${classFormLable.get('phone')}`}>
                        <span className={`footer__form-lable`}>Телефон</span>
                        <input type="text"
                            name="phone" 
                            id="phone"
                            onFocus={this.focusInput} required={true} pattern="[0-9\s-]{5,15}"/>
                    </label>
                    <button className="footer__button button" 
                            onClick={this.formSubmit}>Отправить</button>
                </form>
                <div className="footer__info">
                    <nav className="footer__navigation">
                        <p className="footer__info-text">разделы</p>
                        <Link to="/vehicle-monitoring">Мониторинг транспорта</Link>
                        <Link to="/about-company">О компании</Link>
                        <Link to="/contacts">Контакты</Link>
                    </nav>
                    <div className="footer__contacts">
                        <p className="footer__info-text">контакты</p>
                        <p className="footer__info-mail">info@vistar.ru</p>
                        <p className="footer__info-feedback">
                            8 951 551 70 00
                            <a>Заказать звонок</a>
                        </p>
                    </div>
                    <p className="footer__copyright">Copyright © 2025 www.vistar.ru</p>
                </div>
                {/* <CustomMap elementClass="footer__map"/> */}
            </footer> 
        );
    }
}

Footer.contextType = SliderPageScrollEvents;