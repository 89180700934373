import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Routes, Route} from 'react-router-dom';
import AboutCompany from 'src/components/pages/AboutCompany/AboutCompany';
import Contacts from 'src/components/pages/Contacts/Contacts';
import MainPage from 'src/components/pages/MainPage/MainPage';
import VehicleMonitoring from 'src/components/pages/VehicleMonitoring/VehicleMonitoring';

export default class Root extends React.Component {
    public render() {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<MainPage/>}/>
                    <Route path="/vehicle-monitoring" element={<VehicleMonitoring/>} />
                    <Route path="/about-company" element={<AboutCompany/>} />
                    <Route path="/contacts" element={<Contacts/>} />
                </Routes>
            </BrowserRouter>
        );
    }
}