import * as React from 'react';
import Footer from 'src/components/widgets/Footer/Footer';
import Statement from 'src/components/widgets/Statement/Statement';
import statementData from 'src/data/statementDate/statementDate';
import SliderPage from '../../widgets/SliderPage/SliderPage';
import './Contacts.scss';

interface IContactsState {
    classColorDots: string;
}

export default class Contacts extends React.PureComponent<any, IContactsState> {
    public render() {
        const statementProps = statementData.Contacts;
        return (
            <div className={`main-page`}>
                <SliderPage>
                    <Statement {...statementProps} />
                    <Footer />
                </SliderPage>
            </div>
        );
    }
}