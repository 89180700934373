import * as React from 'react';
/**
 * "GPS/ГЛОНАСС" должно быть обернуто в <span> чтобы отображалось как на макете. Пример (<span>GPS/ГЛОНАСС</span>)
 */
const statementData = {
    MainPage: {
        title: (<>Система <span>GPS/ГЛОНАСС</span> мониторинга</>),
        text: `Система мониторинга транспорта ВИСТАР-М - программный комплекс, 
        использующий системы спутниковой навигации GPS и ГЛОНАСС для контроля транспорта, пассажиров, грузов, выполнения логистических задач.`,
        classModifier: `main-page`
    },
    VehicleMonitoring: {
        title: (<><span>GPS/ГЛОНАСС</span> мониторинг транспорта</>),
        text: `Транспорт под контролем!`,
        classModifier: `vehicle-monitoring`
    },
    AboutCompany: {
        title: (<><span>GPS/ГЛОНАСС мониторинг</span> транспорта - это Команда</>),
        text: `Мы - это программисты, дизайнеры, проектировщики, SEO-специалисты, 
        технические специалисты и другие сотрудники, 
        которые создают условия для чёткой и комфортной работы.`,
        classModifier: `about-company`
    },
    Contacts: {
        title: (<><span>Контакты</span></>),
        text: `394019, г. Воронеж, ул. Машиностроителей, 94-21
        ИНН 3665095544  КПП 366501001  ОГРН 1133668040835`,
        classModifier: `contacts`
    }
};

export default statementData;