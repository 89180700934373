import * as React from "react";
import './SatelliteMonitoringOfTransport.scss';

export default class SatelliteMonitoringOfTransport extends React.Component {
    public render() {
        return (
        <section className="satellite-monitoring-of-transport">
            <h2 className="satellite-monitoring-of-transport__title title">Спутниковый мониторинг транспорта от компании «Вистар» – это:</h2>
            <ul className="satellite-monitoring-of-transport__list list">
                <li className="satellite-monitoring-of-transport__item">Удобный интерфейс на мобильных и настольных устройствах</li>
                <li className="satellite-monitoring-of-transport__item">Контроль передвижения по маршруту и графику</li>
                <li className="satellite-monitoring-of-transport__item">Данные о топливной эффективности</li>
                <li className="satellite-monitoring-of-transport__item">Движение транспорта в группах</li>
                <li className="satellite-monitoring-of-transport__item">Решение логистических задач</li>
            </ul>
            <ul className="satellite-monitoring-of-transport__price-list">
                <li className="satellite-monitoring-of-transport__price-item">
                    <h3 className="satellite-monitoring-of-transport__price-title">Тарифные планы на 01.12.2024</h3>
                    <p className="satellite-monitoring-of-transport__price-sum">
                        
                        <span><a href="/files/tarif-plans.pdf">Стоимость программного обеспечения</a></span>
                    </p>
                    <p className="satellite-monitoring-of-transport__price-text">Для скачивания файла нажмите на <a href="/files/tarif-plans.pdf#">ссылку</a></p>
                </li>
            </ul>
            {/* <p className="satellite-monitoring-of-transport__subtext"><b>*350 руб.</b> - более 10 единиц техники, <b>450 руб.</b> - до 10 машин</p> */}
        </section>
        );
    }
}